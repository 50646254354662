import React, {useEffect, useState} from 'react';
import FormSelect from "../../../forms/form-control/FormSelect";
import apiClient from "../../../../utils/apiClient";
import {useForm} from "react-hook-form";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import {updateStateValue} from "../../../../utils/stateHelpers";
import {isEmpty} from "lodash";
import FormInputDate from '../../../forms/form-control/FormInputDate';
import moment from 'moment';
import { useAuthenticate } from '../../../../hooks/auth';

const CLIENT_GROUP_ID = 5;
const INITIAL_DATE_FROM = moment().format("YYYY-MM-DD");
const INITIAL_DATE_TO = moment().add(7, "day").format("YYYY-MM-DD");

/**
 * Filter component for Client Dashboard report
 * @param prop
 * @returns {JSX.Element}
 * @constructor
 */
const ClientReportDashboardFilters = (prop) => {
    const {register, handleSubmit, errors, setValue} = useForm();
    const [financialPeriods, setFinancialPeriods] = useState([]);
    const [clients, setClients] = useState([]);
    const {
        setReportFilters,
        setDashboardData,
    } = prop;

    const { user } = useAuthenticate();
    const isClient = user?.group_id === CLIENT_GROUP_ID;

    const onSubmit = (data) => {
        updateStateValue(setReportFilters, 'isLoading', true);
        updateStateValue(setReportFilters, 'isSubmitted', true);

        apiClient
            .post('getClientDashboardData', ({
                financial_period_id: data.financial_period_id,
                date_from: data.date_from,
                date_to: data.date_to,
                client_id: isClient ? user?.client_id :data.client_id,
            }))
            .then(response => {
                if (isEmpty(response.data)) {
                    setDashboardData(null);
                    return;
                }
                setDashboardData(response.data);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            }).then(function () {
            updateStateValue(setReportFilters, 'isLoading', false);
        });
    }

    useEffect(() => {
        if (isClient) {
            updateStateValue(setReportFilters, 'isLoading', true);
            updateStateValue(setReportFilters, 'isSubmitted', true);

            apiClient
                .post('getClientDashboardData', ({
                    date_from: INITIAL_DATE_FROM,
                    date_to: INITIAL_DATE_TO,
                    client_id: user?.client_id,
                }))
                .then(response => {
                    if (isEmpty(response.data)) {
                        setDashboardData(null);
                        return;
                    }
                    setDashboardData(response.data);
                })
                .catch(function () {
                    //In case of error do nothing!!
                    //let apiClient to handle the error
                }).then(function () {
                updateStateValue(setReportFilters, 'isLoading', false);
            });
        }
    }, [isClient, setDashboardData, setReportFilters, user?.client_id])

    // get list of financial periods
    useEffect(() => {
        apiClient
            .get('getFinancialPeriods')
            .then(response => {
                setFinancialPeriods(
                    response.data.map(
                        ({id, name, is_current}) => {
                            return {
                                id: id, name: name + (is_current ? ' (Current)' : '')
                            }
                        }
                    )
                );
        });
    }, []);

    // get list of active clients
    useEffect(() => {
        apiClient
            .get('getActiveClientsList')
            .then(response => {
                setClients(
                    response.data.activeClientsList.map(
                        ({id, client_name}) => ({id: id, name: client_name})
                    )
                );
            });
    }, []);

    const setDate = (value) => {
        if (value) {
            apiClient
                .post('getFinancialPeriodById', {'id': value})
                .then(response => {
                    let startDate = response.data.start_date;
                    let endDate = response.data.end_date;
                    document.getElementById('date_from').value = startDate;
                    document.getElementById('date_to').value = endDate;
            });
        }
    }

    const resetPeriodFilters = () => {
        setValue('financial_period_id', null);
    }

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row row s-gy">
                <FormSelect
                    id="financial_period_id"
                    label="Monthly Data Period"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-3"
                    options={financialPeriods}
                    register={register}
                    onChange={(e) => setDate(e.target.value)}
                    errors={errors}
                />
                <FormInputDate
                    id="date_from"
                    label="From"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={INITIAL_DATE_FROM}
                    register={register({required: 'required'})}
                    onChange={() => resetPeriodFilters()}
                />
                <FormInputDate
                    id="date_to"
                    label="To"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={INITIAL_DATE_TO}
                    register={register({required: 'required'})}
                    onChange={() => resetPeriodFilters()}
                />
                {!isClient && <FormSelect
                    id="client_id"
                    label="Client"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    options={clients}
                    register={register({required: 'required'})}
                    onChange={(e) => {updateStateValue(setReportFilters, e.target.id, e.target.value)}}
                    errors={errors}
                />}
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default ClientReportDashboardFilters;

import {AccordionItem} from 'react-light-accordion';
import DowntimeChart from './charts/downtimeChart/downtimeChart';
import styled from 'styled-components';
import CostPerFootChart from './charts/costPerFootChart';
import FootagePerShiftChart from './charts/footagePerShiftChart';
import DrillingChart from './charts/drillingChart';

const AccordionItemContainer = styled.div`
    & .accordion-item .title {
        font-size: 1.5rem;
    }
`

const ChartsContainer = styled.div`
    gap: 60px;
`;

const ClientReportDashboardAccordion = ({title, data, withSummaryTooltips = false}) => {

    return (
        <AccordionItemContainer>
            <AccordionItem className="card-header bg-primary" title={title}>
                <div className="p-2">
                    <ChartsContainer className="d-flex overflow-auto">
                        {
                            data.cost_per_foot && (
                                <div style={{minWidth: '800px'}}>
                                    <CostPerFootChart chartData={data.cost_per_foot} withSummaryTooltip={withSummaryTooltips} />
                                </div>
                            )
                        }
                        {
                            data.footage_per_shift && (
                                <div style={{minWidth: '800px'}}>
                                    <FootagePerShiftChart chartData={data.footage_per_shift} withSummaryTooltip={withSummaryTooltips} />
                                </div>
                            )
                        }
                        {
                            data.drilling && (
                                <div style={{minWidth: '800px'}}>
                                    <DrillingChart chartData={data.drilling} withSummaryTooltip={withSummaryTooltips} />
                                </div>
                            )
                        }
                        {
                            data.downtime && (
                                <div style={{minWidth: '800px'}}>
                                    <DowntimeChart chartData={data.downtime} withSummaryTooltip={withSummaryTooltips} />
                                </div>
                            )
                        }
                    </ChartsContainer>
                </div>
            </AccordionItem>
        </AccordionItemContainer>
    )
}

export default ClientReportDashboardAccordion;

import { Pie } from "react-chartjs-2";
import { formatNumberToDisplayWithCommas } from "../../../common/shared";

const BusinessDevelopmentPieChart = ({title, data}) => {

    const labels = data.map(item => item.name);
    const sum = data.reduce((sum, value) => sum + value.number, 0);

    const dataWithPercents = data.map(v => ({...v, percent: v.number > 0 ? Math.max(v.number / sum * 100, 1) : 0}));

    const chartData = {
        labels: labels,
        datasets: [
            {
                data: dataWithPercents,
                parsing: {
                    key: 'percent'
                },
                borderWidth: dataWithPercents.length === 1 ? 0 : undefined,
            },
        ],
    }
  
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'right'
            },
            autocolors: {
                mode: 'data'
            },
            title: {
                display: true,
                position: 'top',
                color: 'black',
                text: title,
                font: {
                    size: 20,
                    family: "work-Sans, sans-serif",
                }
            },
            tooltip: {
                callbacks: {
                    label: (Item) => `${Item.label}: ${formatNumberToDisplayWithCommas(Item.raw.number)} (${Item.raw.percentage < 1 ? '<1' : Item.raw.percentage}%)`,
                }
            }
        }
    }

  return (
    <Pie data={chartData} options={options} />
  )
}

export default BusinessDevelopmentPieChart
import React from 'react';
import Table from "../../common/table/table.js";
import OpportunityReportsTable from "./opportunityReportsTable.js";
import MessageEmptyData from "../../common/messageEmptyData.js";

/**
 * Reports body component
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const OpportunityReportsBody = (prop) => {
    const {filters, tableData} = prop;

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <Table
                columns={OpportunityReportsTable()}
                data={tableData}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                getExportFileName={() => 'reports'}
                withGlobalSearch={true}
                scrollable={true}
            />
        </div>
    );
}

export default OpportunityReportsBody;

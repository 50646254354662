import React from 'react';
import {get} from "lodash";

/**
 * Form input for email
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FormInputEmail = (props) => {
    const {
        id,
        label,
        value,
        type = "email",
        className,
        disabled,
        onChange,
        errors,
        register,
        isControlled = false,
        autocomplete = "chrome-off",
        ...rest
    } = props;

    return (
        <div className={className}>
            {label && <label htmlFor={id}>{label}</label>}
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroupPrepend">@</span>
                </div>
                <input
                    id={id}
                    name={id}
                    className="form-control"
                    disabled={disabled}
                    type={type}
                    // set value or defaultValue
                    {...(isControlled ? {value: value} : {defaultValue: value})}
                    onChange={onChange}
                    ref={register}
                    autoComplete={autocomplete}
                    {...rest}
                />
                {errors && <span>{get(errors, id)?.message}</span>}
            </div>
        </div>
    );
}

export default React.memo(FormInputEmail);

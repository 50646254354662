import React, {useState} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import ContactMatrixFilters from "./contactMatrixFilters";
import ContactMatrixBody from "./contactMatrixBody";
import LoadingIndicator from "../../common/loadingIndicator";

/**
 * Contact matrix Component
 * @returns {JSX.Element}
 * @constructor
 */
const ContactMatrix = () => {
    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState({
        status: '1',
        company_name: '',
        state_id: 'all',
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Contact Matrix" parent="Business Development"/>
            <ContactMatrixFilters {...{filters, setFilters, setTableData}}/>
            <ContactMatrixBody {...{filters, tableData, setTableData}}/>
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default ContactMatrix;

import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import apiClient from "../../../utils/apiClient";
import FormSelect from "../../forms/form-control/FormSelect";
import FormSubmitButton from "../../forms/form-control/FormSubmitButton";
import {updateStateValue} from "../../../utils/stateHelpers";
import {isEmpty} from "lodash";
import FormSearchableSelect from "../../forms/form-control/FormSearchableSelect";
import FormSelectAllCountries from "../../forms/form-control/FormSelectAllCountries";

/**
 * Contact matrix filters component
 * @returns {JSX.Element}
 * @constructor
 */
const ContactMatrixFilters = (prop) => {
    const {filters, setFilters, setTableData} = prop;
    const {register, handleSubmit, control, errors, setValue} = useForm();
    const [companies, setCompanies] = useState([]);
    const [states, setStates] = useState([]);

    useEffect(() => {
        updateStateValue(setFilters, 'company_name', '');
        setValue('company_name', '');

        let status = 'all';
        if (filters.status === '1') {
            status = 'active';
        } else if (filters.status === '0') {
            status = 'inactive';
        }

        apiClient
            .get(`getCompanies?status=${status}`)
            .then(response => {
                setCompanies(
                    response.data.map(
                        company => ({value: company, label: company})
                    )
                );
            });
    }, [filters.status, setFilters, setValue]);

    useEffect(() => {
        apiClient
            .get('getALLStates')
            .then(response => {
                const optionItems = response.data.states.map((state, index) =>
                    (index === 0)
                        ?
                        (<optgroup key={index} label='United States :'/>)
                        :
                        (index > 0 && index <= 49)
                            ?
                            (<option key={index} value={state.id}>{state.abbreviation + ' - ' + state.name}</option>)
                            :
                            (index === 50)
                                ?
                                (<optgroup key={index} label='Canada Provinces :'/>)
                                :
                                (index === 62)
                                    ?
                                    (<optgroup key={index} label='Australia States :'/>)
                                    :
                                    (<option key={index} value={state.id}>{state.abbreviation + ' - ' + state.name}</option>)
                );

                setStates(optionItems);
            });
    }, []);

    const filterSelectionHandler = (e) => {
        const {id, value} = e.target;
        updateStateValue(setFilters, id, value);
    }

    const onSubmit = (data) => {
        if (data.company_name) {
            data.company_name = data.company_name.value;
        }

        updateStateValue(setFilters,'isLoading', true);
        updateStateValue(setFilters,'isSubmitted', true);

        apiClient
            .post('getContactMatrix', data)
            .then(response => {
                if (isEmpty(response.data)) {
                    setTableData([]);
                    return;
                }
                setTableData(response.data);
            })
            .catch(function () {
                setTableData([]);
            }).then(function () {
            updateStateValue(setFilters,'isLoading', false);
        });
    }

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row s-gy">
                <FormSelect
                    id="status"
                    label="Status"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={filters.status}
                    onChange={filterSelectionHandler}
                    options={[{value: 1, label: "Active"}, {value: 0, label: "Inactive"}]}
                    register={register({required: false})}
                    errors={errors}
                />
                <FormSearchableSelect
                    id="company_name"
                    label="Company Name"
                    options={companies}
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    register={register({required: false})}
                    control={control}
                    errors={errors}
                    customStyles={{
                        menu: (provided) => ({
                            ...provided,
                            'z-index': 10000,
                        }),
                    }}
                />
                <FormSelectAllCountries
                    id="state_id"
                    label="State"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    options={states}
                    onChange={filterSelectionHandler}
                    value={filters.state_id}
                    register={register({required: false})}
                    errors={errors}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default ContactMatrixFilters;

import React from "react";
import {FaFileDownload, FaTrash} from "react-icons/fa";

const EditOpportunityFilesTableColumns = ({ onDownload, setDeleteModal }) => {
    let tableColumns = [];

    tableColumns.push(
        {
            Header: 'File Name',
            accessor: 'original_name',
        },
    );

    tableColumns.push(
        {
            Header: 'Actions',
            Cell: (cell) => {
                const row = cell.row.original;

                return (
                    <>
                        <FaFileDownload
                            color='#A51E37'
                            title="Download"
                            onClick={() => {
                                onDownload(row);
                            }}
                        />
                        <FaTrash
                            color='#A51E37'
                            title="Remove"
                            onClick={() => {
                                const msg = <p>Are you sure that you want to remove the file <b>{row.original_name}</b></p>;
                                // setSelectedRow(row);
                                setDeleteModal({
                                    message: msg,
                                    isOpen: true,
                                })
                            }}
                        />
                    </>
                )
            }
        }
    );

    return tableColumns;
}

export default EditOpportunityFilesTableColumns;
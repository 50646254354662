import { Bar } from "react-chartjs-2";
import { formatNumberToDisplayWithCommas } from "../../../common/shared";

const BusinessDevelopmentBarChart = ({title, data}) => {

    const labels = data.map(item => item.name);

    const chartData = {
        labels: labels,
        datasets: data.map(item => ({
          data: [item],
          label: item.name,
          maxBarThickness: 20,
          stack: 'bar',
          parsing: {
            xAxisKey: 'name',
            yAxisKey: 'number',
        }}))
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                title: title,
                beginAtZero: true,
                suggestedMin: 0,
                suggestedMax: 1,
                ticks: {
                    autoSkip: false,
                    precision: 0,
                }
            },
            x: {
                grid: {
                    drawOnChartArea: false,
                },
            },
        },
        plugins: {
            title: {
                display: true,
                position: 'top',
                color: 'black',
                text: title,
                font: {
                    size: 20,
                    family: "work-Sans, sans-serif",
                }
            },
            legend: {
                display: true,
                position: 'right',
            },
            tooltip: {
                callbacks: {
                    label: (Item) => `${Item.label}: ${formatNumberToDisplayWithCommas(Item.raw.number)} (${Item.raw.percentage < 1 ? '<1' : Item.raw.percentage}%)`,
                }
            },
        },
    }

    return (
        <Bar data={chartData} options={options}/>
    )
}

export default BusinessDevelopmentBarChart
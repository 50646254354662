import Accordion from 'react-light-accordion';
import { useEffect } from 'react';
import * as Shared from "../../../common/shared";
import ClientDashboardAccordion from './clientReportDashboardAccordion';
import MessageEmptyData from '../../../common/messageEmptyData';
import { isEmpty } from 'lodash';

const ClientReportDashboardBody = ({dashboardData, reportFilters}) => {

    useEffect(() => {
        Shared.toggleAllAccordionItems();
    }, [dashboardData])

    if (!reportFilters.isSubmitted) {
        return null;
    }

    if (isEmpty(dashboardData)) {
        return <MessageEmptyData />;
    }

    return (
        <div className="card" id="accordion_card">
            <div className="card-body">
                <div className="default-according panel-accordion" id="accordionclose">
                    <Accordion atomic="false">
                        {
                            Object.keys(dashboardData).map(key => (
                                <ClientDashboardAccordion
                                    key={key}
                                    title={key}
                                    data={dashboardData[key]}
                                    withSummaryTooltips
                                />
                            ))
                        }
                    </Accordion>
                </div>
            </div>
        </div>
    )
}

export default ClientReportDashboardBody;
import React, {useEffect, useState} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import apiClient from '../../../utils/apiClient';
import { isEmpty } from 'lodash';
import EditOpportunityForm from './editOpportunityForm';

const EditOpportunity = (props) => {
    const opportunityId  = props.match.params.opportunity_id;
    const [opportunity, setOpportunity] = useState({});
    const [states, setStates] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [disciplines, setDisciplines] = useState([]);
    const [equipmentTypes, setEquipmentTypes] = useState([]);
    const [salesmen, setSalesmen] = useState([]);

    useEffect(() => {
        apiClient
            .get(`getOpportunity/${opportunityId}`)
            .then(response => {
                setOpportunity(response.data);
            });
    }, [opportunityId]);

    useEffect(() => {
        apiClient
            .get('getUSAStates')
            .then(response => {
                setStates(
                    response.data.states.map(
                        ({id, abbreviation}) => ({id: id, name: abbreviation})
                    )
                );
            });

        apiClient
            .get('getDisciplines')
            .then(response => {
                setDisciplines(response.data.disciplines);
        });

        apiClient
            .get('getCompanies')
            .then(response => {
                setCompanies(response.data.map(company => ({value: company, label: company})));
            });

        apiClient
            .get('getEquipmentTypes')
            .then(response => {
                setEquipmentTypes(
                    response.data.map(
                        type => ({id: type.id, name: type.name})
                    )
                )
            });

        apiClient
            .get('getSalesman')
            .then(response => {
                setSalesmen(
                    response.data.map(
                        salesman => ({id: salesman, name: salesman})
                    )
                );
            })
    }, []);

    if (
        isEmpty(opportunity) ||
        isEmpty(states) || 
        isEmpty(companies) ||
        isEmpty(disciplines) ||
        isEmpty(equipmentTypes) ||
        isEmpty(salesmen)
    ) {
        return null;
    }

    return (
        <>
            <Breadcrumb title="Edit Opportunity" parent="Business Development" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <EditOpportunityForm
                            opportunity={opportunity}
                            contacts={contacts}
                            setContacts={setContacts}
                            companies={companies}
                            states={states}
                            disciplines={disciplines}
                            equipmentTypes={equipmentTypes}
                            salesmen={salesmen}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditOpportunity;

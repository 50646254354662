import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import Breadcrumb from '../../common/breadcrumb';
import apiClient from '../../../utils/apiClient';
import FormInputText from '../../forms/form-control/FormInputText';
import FormInputTextArea from '../../forms/form-control/FormInputTextArea';
import { toast } from 'react-toastify';
import FormSelect from '../../forms/form-control/FormSelect';
import FormInputEmail from '../../forms/form-control/FormInputEmail';
import {limitInput} from "../../common/shared";
import FormSelectAllCountries from "../../forms/form-control/FormSelectAllCountries";

const CreateContact = () => {
    const { handleSubmit, register, errors, reset } = useForm();
    const [states, setStates] = useState([]);

    useEffect(() => {
        apiClient
        .get('getALLStates')
        .then(response => {
            const optionItems = response.data.states.map((state, index) =>
                (index === 0)
                    ?
                        (<optgroup key={index} label='United States :'/>)
                    :
                        (index > 0 && index <= 49)
                            ?
                                (<option key={index} value={state.id}>{state.abbreviation + ' - ' + state.name}</option>)
                            :
                                (index === 50)
                                    ?
                                        (<optgroup key={index} label='Canada Provinces :'/>)
                                    :
                                        (index === 62)
                                            ?
                                                (<optgroup key={index} label='Australia States :'/>)
                                            :
                                                (<option key={index} value={state.id}>{state.abbreviation + ' - ' + state.name}</option>)
            );

            setStates(optionItems);

        });
    }, [])

    const onSubmit = (data) => {
        apiClient
            .post('createContact', data)
            .then(() => {
                toast.success("Contact has been created.");
                reset();
            })
            .catch((error) => {
                console.error(error);
            });
    }

    return (
        <>
            <Breadcrumb title="Create Contact" parent="Business Development" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <form
                            className="needs-validation"
                            noValidate=""
                            id="create_contact_form"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div className="card">
                                <div className="card-body">
                                    <div className="form-row mb-3">
                                        <FormInputText
                                            id="company_name"
                                            label="Company Name"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            register={register({required: "required"})}
                                            errors={errors}
                                        />
                                        <FormInputText
                                            id="first_name"
                                            label="Contact First Name"
                                            type="text"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            register={register({required: "required"})}
                                            errors={errors}
                                        />
                                        <FormInputText
                                            id="last_name"
                                            label="Contact Last Name"
                                            type="text"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            register={register({required: "required"})}
                                            errors={errors}
                                        />
                                        <FormInputText
                                            id="title"
                                            label="Contact Title"
                                            type="text"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            register={register({required: "required"})}
                                            errors={errors}
                                        />
                                    </div>
                                    <div className="form-row mb-3">
                                        <FormInputText
                                            id="phone"
                                            label="Contact Phone Number"
                                            type="number"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            maxLength="10"
                                            onInput={(e) => limitInput(e.target)}
                                            register={
                                                register({
                                                    min: {value: 1000000000, message: "phone number must be 10 digits"},
                                                    required: "required"
                                                })
                                            }
                                            errors={errors}
                                        />
                                        <FormInputEmail
                                            id="email"
                                            label="Contact Email Address"
                                            type="email"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            register={register({required: "required"})}
                                            errors={errors}
                                        />
                                        <FormInputText
                                            id="city"
                                            label="City"
                                            type="text"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            register={register({required: "required"})}
                                            errors={errors}
                                        />
                                        <FormSelectAllCountries
                                            id="state_id"
                                            label="State"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            options={states}
                                            register={register({required: "required"})}
                                            errors={errors}
                                        />
                                    </div>
                                    <div className="form-row mb-3">
                                        <FormSelect
                                            id="client_status"
                                            label="Client Status"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            options={[{value: "Prospective", label: "Prospective"}, {value: "Existing", label: "Existing"}]}
                                            register={register({required: "required"})}
                                            errors={errors}
                                        />
                                        <FormSelect
                                            id="contact_type"
                                            label="Contact Type"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            options={[{value: "Client", label: "Client"}, {value: "Consultant", label: "Consultant"}]}
                                            register={register({required: "required"})}
                                            errors={errors}
                                        />
                                    </div>
                                    <div className='form-row'>
                                        <FormInputTextArea
                                            id="comments"
                                            rows={6}
                                            label="Comments"
                                            className="col-md-12 mb-12"
                                            register={register({
                                                required: false,
                                            })}
                                            errors={errors}
                                        />
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-primary" type="submit">Create</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateContact;

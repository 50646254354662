import React, {useEffect, useState} from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FormInputText from "../../forms/form-control/FormInputText";
import {useForm} from "react-hook-form";
import FormSelect from "../../forms/form-control/FormSelect";
import apiClient from "../../../utils/apiClient";
import {toast} from "react-toastify";
import {replaceObjFromArray} from "../../../utils/arrayHelpers";
import FormInputEmail from "../../forms/form-control/FormInputEmail";
import FormInputTextArea from "../../forms/form-control/FormInputTextArea";
import FormSelectAllCountries from "../../forms/form-control/FormSelectAllCountries";

/**
 * Edit contact Modal
 * @returns {JSX.Element}
 * @constructor
 */
const EditContactModal = (props) => {
    const {modal, setModal, selectedRow, tableData, setTableData} = props;
    const {register, handleSubmit, errors} = useForm();
    const [states, setStates] = useState([]);

    useEffect(() => {
        apiClient
        .get('getALLStates')
        .then(response => {
            const optionItems = response.data.states.map((state, index) =>
                (index === 0)
                    ?
                    (<optgroup key={index} label='United States :'/>)
                    :
                    (index > 0 && index <= 49)
                        ?
                        (<option key={index} value={state.id}>{state.abbreviation + ' - ' + state.name}</option>)
                        :
                        (index === 50)
                            ?
                            (<optgroup key={index} label='Canada Provinces :'/>)
                            :
                            (index === 62)
                                ?
                                (<optgroup key={index} label='Australia States :'/>)
                                :
                                (<option key={index} value={state.id}>{state.abbreviation + ' - ' + state.name}</option>)
            );

            setStates(optionItems);
        });
    }, []);

    const onSave = (data) => {
        apiClient
            .post(`updateContact/${selectedRow.id}`, data)
            .then(response => {
                setTableData(
                    replaceObjFromArray(
                        tableData, "id", selectedRow.id, response.data,
                    )
                );
                toast.success(response.data.message);
                toggle();
            });
    }

    // toggle for open/close the modal form
    const toggle = () => setModal({...modal, isOpen: !modal.isOpen});

    return (
        <Modal isOpen={modal.isOpen} toggle={toggle} className="modal-lg modal-body" centered={true} >
            <form
                className="needs-validation"
                noValidate=""
                id="edit_contact_form"
                onSubmit={handleSubmit(onSave)}
            >
                <ModalHeader toggle={toggle}>Edit Contact</ModalHeader>
                <ModalBody>
                    <div className="form-row">
                        <FormInputText
                            id="id"
                            type="hidden"
                            value={selectedRow.id}
                            register={register}
                        />
                        <FormInputText
                            id="company_name"
                            label="Company Name"
                            type="text"
                            className="col-md-4 mb-3"
                            value={selectedRow.company_name}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormInputText
                            id="first_name"
                            label="First Name"
                            type="text"
                            className="col-md-4 mb-3"
                            value={selectedRow.first_name}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormInputText
                            id="last_name"
                            label="Last Name"
                            type="text"
                            className="col-md-4 mb-3"
                            value={selectedRow.last_name}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                    </div>
                    <div className="form-row">
                        <FormInputText
                            id="title"
                            label="Contact Title"
                            type="text"
                            className="col-md-4 mb-3"
                            value={selectedRow.title}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormInputText
                            id="phone"
                            label="Contact Phone"
                            type="number"
                            className="col-md-4 mb-3"
                            value={selectedRow.phone}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormInputEmail
                            id="email"
                            label="Contact Email Address"
                            type="email"
                            className="col-md-4 mb-3"
                            value={selectedRow.email}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                    </div>
                    <div className="form-row">
                        <FormInputText
                            id="city"
                            label="City"
                            type="text"
                            value={selectedRow.city}
                            className="col-md-4 mb-3"
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormSelectAllCountries
                            id="state_id"
                            label="State"
                            className="col-md-6 mb-3"
                            options={states}
                            value={selectedRow.state_id}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormSelect
                            id="client_status"
                            label="Client Status"
                            className="col-md-4 mb-3"
                            options={[{value: "Prospective", label: "Prospective"}, {value: "Existing", label: "Existing"}]}
                            value={selectedRow.client_status}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormSelect
                            id="contact_type"
                            label="Contact Type"
                            className="col-md-4 mb-3"
                            value={selectedRow.contact_type}
                            options={[{value: "Client", label: "Client"}, {value: "Consultant", label: "Consultant"}]}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                    </div>
                    <div className='form-row'>
                        <FormInputTextArea
                            id="comments"
                            rows={6}
                            label="Comments"
                            value={selectedRow.comments}
                            className="col-md-12 mb-12"
                            register={register({
                                required: false,
                            })}
                            errors={errors}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit">Save</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default EditContactModal;

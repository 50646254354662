import React, {useEffect, useState} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import LoadingIndicator from "../../common/loadingIndicator";
import apiClient from "../../../utils/apiClient";
import {isEmpty} from "lodash";
import BusinessDevelopmentPieChart from './charts/businessDevelopmentPieChart';
import BusinessDevelopmentBarChart from './charts/businessDevelopmentBarChart';
import ChartContainer from './chartContainer';

/**
 * Business development dashboard component
 * @returns {JSX.Element}
 * @constructor
 */

const BusinessDevelopmentDashboard = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        apiClient.get('getBusinessDevelopmentDashboardData')
            .then(response => {
                if (isEmpty(response.data)) {
                    setData([]);
                    return;
                }
                setData(response.data);
            })
        .then(function () {
            setIsLoading(false);
        });
    }, []);

    return (
        <div className="container-fluid">
            <Breadcrumb title="Dashboard" parent="Business Development"/>
                {
                    !isEmpty(data) && (
                        <div className="row">
                            <ChartContainer>
                                <BusinessDevelopmentPieChart
                                    data={data.number_by_report_types.data}
                                    title="# of opportunities by report type"
                                />
                            </ChartContainer>
                            <ChartContainer>
                                <BusinessDevelopmentBarChart
                                    data={data.number_by_equipment_types.data}
                                    title="# of pipeline opportunities by equipment type"
                                />
                            </ChartContainer>
                            <ChartContainer>
                                <BusinessDevelopmentPieChart
                                    data={data.value_by_equipment_types.data}
                                    title="Value ($) of pipeline opportunities by equipment type"
                                />
                            </ChartContainer>
                            <ChartContainer>
                                <BusinessDevelopmentPieChart
                                    data={data.value_by_discipline.data}
                                    title="Value ($) of pipeline opportunities by discipline"
                                />
                            </ChartContainer>
                            <ChartContainer>
                                <BusinessDevelopmentPieChart
                                    data={data.percents_by_discipline.data}
                                    title="% of pipeline opportunities by discipline"
                                />
                            </ChartContainer>
                            <ChartContainer>
                                <BusinessDevelopmentPieChart
                                    data={data.percents_by_states.data}
                                    title="% of pipeline opportunities by state"
                                />
                            </ChartContainer>
                            <ChartContainer>
                                <BusinessDevelopmentPieChart
                                    data={data.value_by_state.data}
                                    title="Value ($) of pipeline opportunities by state"
                                />
                            </ChartContainer>
                            <ChartContainer>
                                <BusinessDevelopmentBarChart
                                    data={data.value_by_months.data}
                                    title="Value ($) of pipeline opportunities by month"
                                />
                            </ChartContainer>
                            <ChartContainer>
                                <BusinessDevelopmentBarChart
                                    data={data.number_by_months.data}
                                    title="# of pipeline/awarded opportunities by month"
                                />
                            </ChartContainer>
                        </div>
                    )  
                }
            <LoadingIndicator isLoading={isLoading}/>
        </div>
    );
};

export default BusinessDevelopmentDashboard;

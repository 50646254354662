import apiClient from '../../../utils/apiClient';
import FormInputText from '../../forms/form-control/FormInputText';
import FormInputTextArea from '../../forms/form-control/FormInputTextArea';
import FormSelect from '../../forms/form-control/FormSelect';
import FormInputNumber from '../../forms/form-control/FormInputNumber';
import FormInputDate from '../../forms/form-control/FormInputDate';
import FormInputFile from '../../forms/form-control/FormInputFile';
import OPPORTUNITY_REPORT_TYPE_OPTIONS from "../opportunityTypeOptions";
import FileManager from './editOpportunityFilesTable';
import { useForm } from 'react-hook-form';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import FormSearchableSelect from "../../forms/form-control/FormSearchableSelect";

const EditOpportunityForm = ({
    opportunity, contacts, setContacts, companies, states,
    disciplines, equipmentTypes, salesmen,
}) => {
    const [showDate, setShowDate] = useState(0);
    const { handleSubmit, register, control, errors, watch, setValue } = useForm();
    const [isUpdated, setIsUpdated] = useState(false);
    const companyName = watch('company_name', '')

    useEffect(() => {
        if (companyName) {
            apiClient
                .get(`getCompanyContacts?company_name=${companyName.value}`)
                .then(response => {
                    const contacts = response.data.map(
                        contact => ({id: contact.id, label: contact.full_name, data: contact})
                    )
                    setContacts(contacts);
                });
        } else {
            setContacts([]);
        }
    }, [companyName, setContacts]);

    const onCompanySelect = () => {
        setValue('contact_id', null);
        setValue('contact_title', '');
        setValue('contact_email', '');
        setValue('contact_phone', '');
    }

    const isOpportunityContactDeactivated = opportunity.contact.status === 0;
    const isCompanyActive = companies.map(company => company.name).includes(opportunity.company_name);
    const companyOptions = [
        ...(isOpportunityContactDeactivated && !isCompanyActive  ? [{
            value: opportunity.company_name,
            label: opportunity.company_name,
        }] : []),
        ...companies,
    ];

    const isAnotherCompanySelected = companyName !== opportunity.company_name;
    const contactOptions = useMemo(() => ([
        ...(isOpportunityContactDeactivated && !isAnotherCompanySelected  ? [{
            id: opportunity.contact.id,
            label: `${opportunity.contact.first_name} ${opportunity.contact.last_name}`,
            data: opportunity.contact,
        }] : []),
        ...contacts,
    ]), [contacts, isAnotherCompanySelected, isOpportunityContactDeactivated, opportunity.contact])

    const onContactSelect = (event) => {
        const contactId = Number(event.target.value);
        const contact = contactOptions.find(contact => contact.id === contactId);
        if (contact) {
            setValue('contact_title', contact.data.title);
            setValue('contact_email', contact.data.email);
            setValue('contact_phone', contact.data.phone);
        } else {
            setValue('contact_title', '');
            setValue('contact_email', '');
            setValue('contact_phone', '');
        }
    }

    const showSubmittedOnDate = (e) => {
        // when option Tender is selected
        if (e.target.value === 'Tender') {
            setShowDate(1);
        } else {
            setShowDate(0);
        }
    }

    const onSubmit = (data) => {
        // override company name value
        data.company_name = data.company_name.value;

        const formData = new FormData();
        for (const key in data) {
            switch (key) {
                case 'files':
                    for (const file of data[key]) {
                        formData.append('files[]', file, file.name);
                    }
                    break;
                case 'company_name':
                    if (data.company_name !== opportunity.company_name || data.contact_id !== opportunity.contact.id.toString()) {
                        formData.append('company_name', data.company_name);
                    }
                    break;
                case 'contact_id':
                    if (data.company_name !== opportunity.company_name || data.contact_id !== opportunity.contact.id.toString()) {
                        formData.append('contact_id', data.contact_id);
                    }
                    break;
                default:
                    formData.append(key, data[key]);
            }
        }
        apiClient
            .post(`updateOpportunity/${opportunity.id}`, formData)
            .then(() => {
                toast.success("Opportunity has been updated.");
                setIsUpdated(true);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const [isContactSet, setIsContactSet] = useState(false);

    useEffect(() => {
        if (opportunity.report_type === 'Tender') {
            setShowDate(1);
        }

        if (isEmpty(contactOptions) || isContactSet) {
            return;
        }
        setValue('contact_id', opportunity.contact.id);
        setIsContactSet(true);
    }, [contactOptions, contacts, isContactSet, opportunity.contact.id, setValue, opportunity.report_type]);

    return (
        <form
            className="needs-validation"
            noValidate=""
            id="create_job_form"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="card">
                <div className="card-body">
                    <div className="form-row mb-3">
                        <FormSelect
                            id="report_type"
                            label="Report Type"
                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                            options={OPPORTUNITY_REPORT_TYPE_OPTIONS}
                            value={opportunity.report_type}
                            register={register({required: "required"})}
                            errors={errors}
                            onChange={(e) => showSubmittedOnDate(e)}
                        />
                        <FormSearchableSelect
                            id="company_name"
                            label="Company Name"
                            options={companyOptions}
                            value={opportunity.company_name}
                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                            rules={{required: "required"}}
                            control={control}
                            // disabling for now
                            isDisabled={true}
                            onChange={onCompanySelect}
                            errors={errors}
                        />
                        <FormInputText
                            id="project_name"
                            label="Project Name"
                            type="text"
                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                            register={register({required: "required"})}
                            value={opportunity.project_name}
                            errors={errors}
                        />
                        <FormInputText
                            id="project_city"
                            label="Project City"
                            type="text"
                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                            register={register({required: "required"})}
                            value={opportunity.project_city}
                            errors={errors}
                        />
                    </div>
                    <div className="form-row mb-3">
                        <FormSelect
                            id="state_id"
                            label="State"
                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                            options={states}
                            register={register({required: "required"})}
                            value={opportunity.state_id}
                            errors={errors}
                        />
                        <FormSelect
                            id="contact_id"
                            label="Contact Name"
                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                            options={contactOptions}
                            register={register({ required: true })}
                            value={opportunity.contact.id}
                            errors={errors}
                            onChange={onContactSelect}
                        />
                        <FormInputText
                            id="contact_title"
                            label="Contact Title"
                            type="text"
                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                            value={opportunity.contact.title}
                            register={register()}
                            disabled
                            errors={errors}
                        />
                        <FormInputText
                            id="contact_email"
                            label="Email Address"
                            type="text"
                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                            value={opportunity.contact.email}
                            register={register()}
                            disabled
                            errors={errors}
                        />
                    </div>
                    <div className="form-row mb-3">
                        <FormInputText
                            id="contact_phone"
                            label="Phone Number"
                            type="text"
                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                            value={opportunity.contact.phone}
                            register={register()}
                            disabled
                            errors={errors}
                        />
                        <FormSelect
                            id="discipline_id"
                            label="Discipline"
                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                            options={disciplines}
                            register={register({ required: 'required' })}
                            value={opportunity.discipline_id}
                            errors={errors}
                        />
                        <FormSelect
                            id="equipment_type_id"
                            label="Equipment Type"
                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                            options={equipmentTypes}
                            register={register({ required: 'required' })}
                            value={opportunity.equipment_type_id}
                            errors={errors}
                        />
                        <FormInputNumber
                            id="project_value"
                            label="Project Value ($)"
                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                            value={opportunity.project_value}
                            register={register()}
                            errors={errors}
                        />
                    </div>
                    <div className="form-row mb-3">
                        <FormInputDate
                            id="project_start_date"
                            label="Project Start Date"
                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                            value={opportunity.project_start_date}
                            register={register({required: false})}
                            errors={errors}
                        />
                        <FormSelect
                            id="salesman"
                            label="Salesman"
                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                            options={salesmen}
                            register={register({ required: 'required' })}
                            value={opportunity.salesman}
                            errors={errors}
                        />
                        { showDate === 1 &&
                            <FormInputDate
                                id="submitted_on"
                                label="Submitted On"
                                className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                value={opportunity.submitted_on}
                                register={register({ required: 'required' })}
                                errors={errors}
                            />
                        }
                        <FormInputDate
                            id="due_on"
                            label="Due On"
                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                            value={opportunity.due_on}
                            register={register({required: false})}
                            errors={errors}
                        />
                    </div>
                    <div className='form-row'>
                        <FormInputTextArea
                            id="scope_of_work"
                            rows={6}
                            label="Scope of Work"
                            className="col-md-12 mb-12"
                            register={register({
                                required: false,
                            })}
                            value={opportunity.scope_of_work}
                            errors={errors}
                        />
                    </div>
                    <div className='form-row'>
                        <FormInputTextArea
                            id="reason_lost"
                            rows={6}
                            label="Reason Lost"
                            className="col-md-12 mb-12"
                            register={register({
                                required: false,
                            })}
                            value={opportunity.reason_lost}
                            errors={errors}
                        />
                    </div>
                    <div className='form-row'>
                        <FormInputTextArea
                            id="comments"
                            rows={6}
                            label="Comments"
                            className="col-md-12 mb-12"
                            register={register({
                                required: false,
                            })}
                            value={opportunity.comments}
                            errors={errors}
                        />
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <h4>Files</h4>
                            <div className="form-row">
                                <FormInputFile
                                    id="files"
                                    className="col-md-12"
                                    register={register}
                                />
                            </div>
                            <FileManager
                                fileableId={opportunity.id}
                                fileableType ="App\Models\Opportunity"
                                isUpdated={isUpdated}
                                setIsUpdated={setIsUpdated}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <button className="btn btn-primary" type="submit">Save</button>
        </form>
    )
}

export default EditOpportunityForm;

import React from "react";
import {FaPencilAlt, FaEyeSlash, FaEye} from "react-icons/fa";
import {formatPhoneNumber} from "../../common/shared";

const ContactMatrixTable = (setSelectedRow, setDeactivateModal, setReactivateModal, setEditModal) => {
    return [
        {
            Header: 'Company',
            accessor: 'company_name',
        },
        {
            Header: 'First Name',
            accessor: 'first_name',
            width: 100,
        },
        {
            Header: 'Last Name',
            accessor: 'last_name',
            width: 100,
        },
        {
            Header: 'Title',
            accessor: 'title',
            width: 100,
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'Phone #',
            accessor: 'phone',
            width: 105,
            Cell: (cell) => {
                let phone = cell.row.original.phone;
                return formatPhoneNumber(phone);
            }
        },
        {
            Header: 'City',
            accessor: 'city',
            width: 100,
        },
        {
            Header: 'State',
            accessor: 'state.abbreviation',
            width: 45,
        },
        {
            Header: 'Status',
            accessor: 'client_status',
            width: 85,
        },
        {
            Header: 'Type',
            accessor: 'contact_type',
            width: 75,
        },
        {
            Header: 'Created',
            accessor: 'created_at',
            width: 70,
        },
        {
            Header: 'Created By',
            accessor: 'createdUser',
            width: 85,
            getCellExportValue: (row) => {
                const user = row.original.createdUser;
                if (user === null) {
                    return "";
                }
                return `${user.first_name.at(0)}. ${user.last_name}`;
            },
            Cell: (cell) => {
                const user = cell.row.original.createdUser;
                if (user === null) {
                    return "";
                }
                return `${user.first_name.at(0)}. ${user.last_name}`;
            }
        },
        {
            Header: 'Modified By',
            accessor: 'updatedUser',
            width: 85,
            getCellExportValue: (row) => {
                const user = row.original.updatedUser;
                if (user === null) {
                    return "";
                }
                return `${user.first_name.at(0)}. ${user.last_name}`;
            },
            Cell: (cell) => {
                const user = cell.row.original.updatedUser;
                if (user === null) {
                    return "";
                }
                return `${user.first_name.at(0)}. ${user.last_name}`;
            },
        },
        {
            Header: 'Modified',
            accessor: 'updated_at',
            width: 70,
        },
        {
            Header: 'Actions',
            width: 60,
            Cell: (cell) => {
                const row = cell.row.original;
                let active = (
                    <>
                        <FaPencilAlt
                            className="actions"
                            title="Edit contact"
                            onClick={() => {
                                setSelectedRow(row);
                                setEditModal({
                                    isOpen: true
                                });
                            }}
                        />
                        {' '}
                        <FaEyeSlash
                            className="actions"
                            title="Deactivate Contact"
                            onClick={() => {
                                const msg = 'Are you sure that you want to deactivate contact: ' + row.company_name + '?';
                                setSelectedRow(row);
                                setDeactivateModal({
                                    message: msg,
                                    isOpen: true,
                                });
                            }}
                        />
                    </>
                );

                let inactive = (
                    <>
                        <FaEye
                            className="actions"
                            title="Reactivate Contact"
                            onClick={() => {
                                const msg = 'Are you sure that you want to reactivate contact: ' + row.company_name + '?';
                                setSelectedRow(row);
                                setReactivateModal({
                                    message: msg,
                                    isOpen: true,
                                });
                            }}
                        />
                    </>
                );

                return (row.status === 1) ? active : inactive;
            }
        }
    ];
}

export default ContactMatrixTable;

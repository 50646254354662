import React from 'react';
import {get} from "lodash";

/**
 * Form input for text
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FormInputText = (props) => {
    const {
        id,
        label,
        value,
        type = "text",
        className,
        onChange,
        errors,
        register,
        disabled,
        maxLength,
        onInput,
        isControlled = false,
        ...rest
    } = props;

    return (
        <div className={className}>
            {label && <label htmlFor={id}>{label}</label>}
            <input
                id={id}
                name={id}
                className="form-control"
                type={type}
                disabled={disabled}
                maxLength={maxLength}
                onInput={onInput}
                // replaced with the one above
                // readOnly={disabled === 'true' ? true : ''}
                // set value or defaultValue
                {...(isControlled ? {value: value} : {defaultValue: value})}
                onChange={onChange}
                ref={register}
                {...rest}
            />
            {errors && <span>{get(errors, id)?.message}</span>}
        </div>
    );
}

export default React.memo(FormInputText);

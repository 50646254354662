import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import Breadcrumb from '../../common/breadcrumb';
import apiClient from '../../../utils/apiClient';
import FormInputText from '../../forms/form-control/FormInputText';
import FormInputTextArea from '../../forms/form-control/FormInputTextArea';
import { toast } from 'react-toastify';
import FormSelect from '../../forms/form-control/FormSelect';
import FormInputDate from '../../forms/form-control/FormInputDate';
import FormInputFile from '../../forms/form-control/FormInputFile';
import FormInputEmail from '../../forms/form-control/FormInputEmail';
import FormSearchableSelect from "../../forms/form-control/FormSearchableSelect";
import OPPORTUNITY_REPORT_TYPE_OPTIONS from "../opportunityTypeOptions";
import {limitInputDaily} from "../../common/shared";

const CreateOpportunity = () => {
    const { handleSubmit, register, errors, reset, control, watch, setValue } = useForm();
    const [states, setStates] = useState([]);
    const [showDate, setShowDate] = useState(0);
    const [companies, setCompanies] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [disciplines, setDisciplines] = useState([]);
    const [equipmentTypes, setEquipmentTypes] = useState([]);
    const [salesmen, setSalesmen] = useState([]);

    useEffect(() => {
        apiClient
            .get('getUSAStates')
            .then(response => {
                setStates(
                    response.data.states.map(
                        ({id, abbreviation}) => ({id: id, name: abbreviation})
                    )
                );
            });

        apiClient
            .get('getDisciplines')
            .then(response => {
                setDisciplines(response.data.disciplines);
        });

        apiClient
            .get('getCompanies')
            .then(response => {
                setCompanies(
                    response.data.map(
                        company => ({value: company, label: company})
                    )
                );
            });

        apiClient
            .get('getEquipmentTypes')
            .then(response => {
                setEquipmentTypes(
                    response.data.map(
                        type => ({id: type.id, name: type.name})
                    )
                )
            });

        apiClient
            .get('getSalesman')
            .then(response => {
                setSalesmen(
                    response.data.map(
                        salesman => ({id: salesman, name: salesman})
                    )
                )
            });
    }, []);
    
    const companyName = watch('company_name');

    useEffect(() => {
        if (companyName) {
            apiClient
                .get(`getCompanyContacts?company_name=${companyName.value}`)
                .then(response => {
                    setContacts(
                        response.data.map(
                            contact => ({id: contact.id, label: contact.full_name, data: contact})
                        )
                    )
                });
        } else {
            setContacts([]);
            setValue('contact_title', '');
            setValue('contact_email', '');
            setValue('contact_phone', '');
        }
    }, [companyName, setValue]);

    const onContactSelect = (event) => {
        const contactId = Number(event.target.value);
        const contact = contacts.find(contact => contact.id === contactId);
        if (contact) {
            setValue('contact_title', contact.data.title);
            setValue('contact_email', contact.data.email);
            setValue('contact_phone', contact.data.phone);
        } else {
            setValue('contact_title', '');
            setValue('contact_email', '');
            setValue('contact_phone', '');
        }
    }

    const showSubmittedOnDate = (e) => {
        // when option Tender is selected
        if (e.target.value === 'Tender') {
            setShowDate(1);
        } else {
            setShowDate(0);
        }
    }

    const onSubmit = (data) => {
        // override company name value
        data.company_name = data.company_name.value;

        const formData = new FormData();
        for (const key in data) {
            switch (key) {
                case 'files':
                    for (const file of data[key]) {
                        formData.append('files[]', file, file.name);
                    }
                    break;
                default:
                    formData.append(key, data[key]);
            }
        }

        apiClient
            .post('createOpportunity', formData)
            .then(() => {
                toast.success("Opportunity has been created.");
                reset();
                // hide submitted on upon form reset
                setShowDate(0);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    return (
        <>
            <Breadcrumb title="Create Opportunity" parent="Business Development" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <form
                            className="needs-validation"
                            noValidate=""
                            id="create_opportunity_form"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div className="card">
                                <div className="card-body">
                                    <div className="form-row mb-3">
                                        <FormSelect
                                            id="report_type"
                                            label="Report Type"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            options={OPPORTUNITY_REPORT_TYPE_OPTIONS}
                                            register={register({required: "required"})}
                                            errors={errors}
                                            onChange={(e) => showSubmittedOnDate(e)}
                                        />
                                        <FormSearchableSelect
                                            id="company_name"
                                            label="Company Name"
                                            options={companies}
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            rules={{required: "required"}}
                                            control={control}
                                            errors={errors}
                                        />
                                        <FormInputText
                                            id="project_name"
                                            label="Project Name"
                                            type="text"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            register={register({required: "required"})}
                                            errors={errors}
                                        />
                                        <FormInputText
                                            id="project_city"
                                            label="Project City"
                                            type="text"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            register={register({required: "required"})}
                                            errors={errors}
                                        />
                                    </div>
                                    <div className="form-row mb-3">
                                        <FormSelect
                                            id="state_id"
                                            label="State"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            options={states}
                                            register={register({required: "required"})}
                                            errors={errors}
                                        />
                                        <FormSelect
                                            id="contact_id"
                                            label="Contact Name"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            options={contacts}
                                            register={register({ required: 'required' })}
                                            errors={errors}
                                            onChange={onContactSelect}
                                        />
                                        <FormInputText
                                            id="contact_title"
                                            label="Contact Title"
                                            type="text"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            register={register()}
                                            disabled
                                            errors={errors}
                                        />
                                        <FormInputEmail
                                            id="contact_email"
                                            label="Email Address"
                                            type="text"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            register={register()}
                                            disabled
                                            errors={errors}
                                        />
                                    </div>
                                    <div className="form-row mb-3">
                                        <FormInputText
                                            id="contact_phone"
                                            label="Phone Number"
                                            type="text"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            register={register()}
                                            disabled
                                            errors={errors}
                                        />
                                        <FormSelect
                                            id="discipline_id"
                                            label="Discipline"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            options={disciplines}
                                            register={register({ required: 'required' })}
                                            errors={errors}
                                        />
                                        <FormSelect
                                            id="equipment_type_id"
                                            label="Equipment Type"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            options={equipmentTypes}
                                            register={register({ required: 'required' })}
                                            errors={errors}
                                        />
                                        <FormInputText
                                            id="project_value"
                                            label="Project Value ($)"
                                            type="number"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            onInput={(e) => limitInputDaily(e.target, 8)}
                                            register={register({required: false})}
                                            errors={errors}
                                        />
                                    </div>
                                    <div className="form-row mb-3">
                                        <FormInputDate
                                            id="project_start_date"
                                            label="Project Start Date"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            register={register({required: false})}
                                            errors={errors}
                                        />
                                        <FormSelect
                                            id="salesman"
                                            label="Salesman"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            options={salesmen}
                                            register={register({ required: 'required' })}
                                            errors={errors}
                                        />
                                        { showDate === 1 &&
                                            <FormInputDate
                                                id="submitted_on"
                                                label="Submitted On"
                                                className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                                register={register({ required: 'required' })}
                                                errors={errors}
                                            />
                                        }
                                        <FormInputDate
                                            id="due_on"
                                            label="Due On"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            register={register({required: false})}
                                            errors={errors}
                                        />
                                    </div>
                                    <div className='form-row'>
                                        <FormInputTextArea
                                            id="scope_of_work"
                                            rows={6}
                                            label="Scope of Work"
                                            className="col-md-12 mb-12"
                                            register={register({
                                                required: false,
                                            })}
                                            errors={errors}
                                        />
                                    </div>
                                    <div className='form-row'>
                                        <FormInputTextArea
                                            id="reason_lost"
                                            rows={6}
                                            label="Reason Lost"
                                            className="col-md-12 mb-12"
                                            register={register({
                                                required: false,
                                            })}
                                            errors={errors}
                                        />
                                    </div>
                                    <div className='form-row'>
                                        <FormInputTextArea
                                            id="comments"
                                            rows={6}
                                            label="Comments"
                                            className="col-md-12 mb-12"
                                            register={register({
                                                required: false,
                                            })}
                                            errors={errors}
                                        />
                                    </div>
                                    <div className="card">
                                        <div className="card-body">
                                            <h4>Files</h4>
                                            <div className="form-row">
                                                <FormInputFile
                                                    id="files"
                                                    className="col-md-12"
                                                    register={register}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-primary" type="submit">Create</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateOpportunity;

import React, {useState} from 'react';
import Table from "../../common/table/table";
import ContactMatrixTable from "./contactMatrixTable";
import MessageEmptyData from "../../common/messageEmptyData";
import ModalConfirmation from "../../common/modal/ModalConfirmation";
import apiClient from "../../../utils/apiClient";
import {toast} from "react-toastify";
import EditContactModal from "./editContactModal.js";
import {removeObjFromArray, replaceObjFromArray} from "../../../utils/arrayHelpers";

/**
 * Contact matrix body component
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const ContactMatrixBody = (prop) => {
    const {filters, tableData, setTableData} = prop;
    const [editModal, setEditModal] = useState({isOpen: false});
    const [deactivateModal, setDeactivateModal] = useState({isOpen: false, message: <p></p>});
    const [reactivateModal, setReactivateModal] = useState({isOpen: false, message: <p></p>});
    const [selectedRow, setSelectedRow] = useState({});

    const ReactivateHandler = () => {
        apiClient
            .post('activateContact', {'id': selectedRow.id})
            .then(response => {
                if (filters.status === '1' || filters.status === '0') {
                    const newTableData = removeObjFromArray(tableData, "id", response.data.id);
                    setTableData(newTableData);
                } else {
                    const newData = {
                        ...selectedRow,
                        status: 1,
                    }
                    const newTableData = replaceObjFromArray(tableData, 'id', response.data.id, newData);
                    setTableData(newTableData);
                }
                toast.success(response.data.message);
            })
            .then(function () {
                //close reactivateModal
                setReactivateModal({...reactivateModal, isOpen: false})
            });
    }

    const DeactivateHandler = () => {
        apiClient
            .post('deactivateContact', {'id': selectedRow.id})
            .then(response => {
                if (filters.status === '1' || filters.status === '0') {
                    const newTableData = removeObjFromArray(tableData, "id", response.data.id);
                    setTableData(newTableData);
                } else {
                    const newData = {
                        ...selectedRow,
                        status: 0,
                    }
                    const newTableData = replaceObjFromArray(tableData, 'id', response.data.id, newData);
                    setTableData(newTableData);
                }
                toast.success(response.data.message);
            })
            .then(function () {
                //close deactivateModal
                setDeactivateModal({...deactivateModal, isOpen: false})
            });
    }

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <Table
                columns={
                    ContactMatrixTable(
                        setSelectedRow, setDeactivateModal, setReactivateModal, setEditModal
                    )
                }
                data={tableData}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                getExportFileName={() => 'contacts'}
                withGlobalSearch={true}
                scrollable={true}
            />
            <ModalConfirmation
                modal={deactivateModal}
                setModal={setDeactivateModal}
                title="Deactivate Contact"
                onConfirm={DeactivateHandler}
            />
            <ModalConfirmation
                modal={reactivateModal}
                setModal={setReactivateModal}
                title="Reactivate Contact"
                onConfirm={ReactivateHandler}
            />
            <EditContactModal
                modal={editModal}
                setModal={setEditModal}
                selectedRow={selectedRow}
                tableData={tableData}
                setTableData={setTableData}
            />
        </div>
    );
}

export default ContactMatrixBody;

import React, {useState} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import OpportunityReportsFilters from "./opportunityReportsFilters";
import OpportunityReportsBody from "./opportunityReportsBody";
import LoadingIndicator from "../../common/loadingIndicator";

/**
 * Reports Component
 * @returns {JSX.Element}
 * @constructor
 */
const OpportunityReports = () => {
    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState({
        report_type: '',
        discipline_id: '',
        state_id: '',
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Reports" parent="Business Development"/>
            <OpportunityReportsFilters {...{filters, setFilters, setTableData}}/>
            <OpportunityReportsBody {...{filters, tableData}}/>
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default OpportunityReports;

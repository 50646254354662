import React, {useEffect, useState} from 'react';
import apiClient from "../../../utils/apiClient";
import TableReport from "../../common/table/table";
import {isEmpty} from "lodash";
import EditOpportunityFilesTableColumns from "./editOpportunityFilesTableColumns";
import ModalConfirmation from "../../common/modal/ModalConfirmation";
import {removeObjFromArray} from "../../../utils/arrayHelpers";
import {toast} from "react-toastify";

/**
 * Table for display/download files
 * @returns {JSX.Element}
 * @constructor
 */
const EditOpportunityFilesTable = (props) => {
    const {fileableId, fileableType, isUpdated, setIsUpdated} = props;
    const [tableData, setTableData] = useState([]);
    const [deleteModal, setDeleteModal] = useState({isOpen: false, message: <p></p>});

    // load list of files
    useEffect(() => {
        if (fileableId || isUpdated) {
            apiClient
                .post('getFiles',
                    {fileable_id: fileableId, fileable_type: fileableType})
                .then(response => {
                    if (isEmpty(response.data)) {
                        setTableData([]);
                        return;
                    }
                    setTableData(response.data);
                    setIsUpdated(false);
                });
        }
    }, [fileableId, fileableType, isUpdated, setIsUpdated]);

    // delete handler
    const onDelete = () => {
        apiClient
            .post('deleteFile', {id: fileableId})
            .then(response => {
                const newTableData = removeObjFromArray(tableData, "id", response.data.id)
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {})
            .then(function () {
                //close deleteModal
                setDeleteModal({...deleteModal, isOpen: false})
            });
    }

    // download handler
    const onDownload = (row) => {
        //get pdf export file and open it in a new window
        apiClient('downloadFile', {
            method: 'POST',
            responseType: 'blob', //Force to receive data in a Blob Format
            data: {id: row.id}
        })
            .then(response => {
                const blob = new Blob([response.data], {type: response.data.type});
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', row.original_name);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
            })
            .catch(function () {});
    }

    return (
        <>
            <TableReport
                columns={EditOpportunityFilesTableColumns(
                    { onDownload, setDeleteModal, allowShareFiles:false}
                )}
                data={tableData}
            />
            <ModalConfirmation
                modal={deleteModal}
                setModal={setDeleteModal}
                title="Remove File"
                onConfirm={onDelete}
            />
        </>
    );
}

export default React.memo(EditOpportunityFilesTable);
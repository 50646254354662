export const OPPORTUNITY_REPORT_TYPE_OPTIONS = [
    {value: "Prospect", label: "Prospect"},
    {value: "Pipeline", label: "Pipeline"},
    {value: "Tender", label: "Tender"},
    {value: "Awarded", label: "Awarded"},
    {value: "Lost", label: "Lost"},
    {value: "Archive", label: "Archive"}
];


export default OPPORTUNITY_REPORT_TYPE_OPTIONS;

import React, { useState } from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import LoadingIndicator from '../../../common/loadingIndicator';
import ClientDashboardFilters from './clientReportDashboardFilters';
import ClientDashboardBody from './clientReportDashboardBody';

/**
 * ClientDashboard Report
 * @returns {JSX.Element}
 * @constructor
 */
const ClientReportDashboard = () => {

    const [dashboardData, setDashboardData] = useState(null);
    const [reportFilters, setReportFilters] = useState({
        isLoading: false,
        isSubmitted: false,
    });

    const reportFilterProps = {
        setReportFilters,
        setDashboardData,
    };

    const reportBodyProps = {
        dashboardData,
        reportFilters
    };

    return (
        <>
            <Breadcrumb title="Client Dashboard" parent="Reports"/>
            <LoadingIndicator isLoading={reportFilters.isLoading}/>
            <ClientDashboardFilters {...reportFilterProps} />
            <ClientDashboardBody {...reportBodyProps} />
        </>
    );
}

export default ClientReportDashboard;

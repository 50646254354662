import React, { useMemo } from 'react';
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import {Bar} from 'react-chartjs-2';
import ChartContainer from './chartContainer';
import {formatNumberToDisplayWithCommas} from '../../../../../components/common/shared';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, annotationPlugin);

const DrillingChart = ({chartData}) => {

    const dataset = chartData.items;
    const labels = chartData.items.map(item => item.date);
    const avgRollingDataset = chartData.items.map(item => item.rolling_avg);
    const monthlyAvgDataset = chartData.items.map(item => item.monthly_avg)

    const data = {
        labels: labels,
        datasets: [
            {
                type: 'bar',
                data: dataset,
                backgroundColor: '#bfbfbf',
                order: 2,
                label: 'Drilling %',
                parsing: {
                    xAxisKey: 'date',
                    yAxisKey: 'avg'
                },
            },
            {
                type: 'line',
                data: avgRollingDataset,
                backgroundColor: '#000000',
                borderColor: '#000000',
                order: 1,
                label: 'Rolling 30-Day Avg',
            },
            {
                type: 'line',
                data: monthlyAvgDataset,
                backgroundColor: '#c00000',
                borderColor: '#c00000',
                order: 0,
                label: 'Monthly Average',
            }
        ],
    }

    const options = {
        scales: {
            y: {
                title: 'Drilling %',
                beginAtZero: true,
                ticks: {
                    callback: function(value) {
                        return `${value}%`;
                    }
                }
            },
            x: {
                grid: {
                    drawOnChartArea: false,
                    tickLength: 10,
                },
                ticks: {
                    autoSkip: false,
                    callback: (_, i) => i % 7 ? '' : labels[i]
                }
            },
        },
        plugins: {
            legend: {
                reverse: true,
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += `${formatNumberToDisplayWithCommas(context.parsed.y, 1)}%`;
                        }
                        return label;
                    }
                },
            },
        },
        maxBarThickness: 20,
    }

    const summaryItems = useMemo(() => [
        {
            label: 'Monthly Avg',
            value: `${formatNumberToDisplayWithCommas(chartData.monthly_avg, 1)}%`,
        },
        {
            label: 'Rolling 30-Day Avg',
            value: `${formatNumberToDisplayWithCommas(chartData.rolling_avg, 1)}%`,
        },
    ], [chartData.rolling_avg, chartData.monthly_avg]);

    return (
        <ChartContainer title="Drilling %" summaryItems={summaryItems}>
            <Bar data={data} options={options}/>
        </ChartContainer>
    )
}

export default DrillingChart;

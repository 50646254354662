import React from "react";
import {FaPencilAlt} from "react-icons/fa";
import { formatNumberToDisplayWithCommas } from "../../common/shared";

const OpportunityReportsTable = () => {
    return [
        {
            Header: 'Discipline',
            accessor: 'discipline',
            width: 130,
        },
        {
            Header: 'Company',
            accessor: 'company_name',
            width: 150,
        },
        {
            Header: 'Project',
            accessor: 'project_name',
            width: 150,
        },
        {
            Header: 'State',
            accessor: 'state.abbreviation',
            width: 45,
        },
        {
            Header: 'Scope of Work',
            accessor: 'scope_of_work',
        },
        {
            Header: 'Type',
            accessor: 'report_type',
            width: 70,
        },
        {
            Header: 'Projected Value',
            accessor: 'project_value',
            width: 100,
            getCellExportValue: (row) => {
                return row.original.project_value;
            },
            Cell: (cell) => {
                const value = cell.row.original.project_value;
                return `$${formatNumberToDisplayWithCommas(value, 0)}`
            }
        },
        {
            Header: 'Projected Start',
            accessor: 'project_start_date',
            width: 70,
        },
        {
            Header: 'Due On',
            accessor: 'due_on',
            width: 70,
        },
        {
            Header: 'Submitted',
            accessor: 'submitted_on',
            width: 75,
        },
        {
            Header: 'Created',
            accessor: 'created_at',
            width: 70,
        },
        {
            Header: 'Modified By',
            accessor: 'updatedUser',
            width: 80,
            getCellExportValue: (row) => {
                const user = row.original.updatedUser;
                if (user === null) {
                    return "";
                }
                return `${user.first_name.at(0)}. ${user.last_name}`;
            },
            Cell: (cell) => {
                const user = cell.row.original.updatedUser;
                if (user === null) {
                    return "";
                }
                return `${user.first_name.at(0)}. ${user.last_name}`;
            },
        },
        {
            Header: 'Modified',
            accessor: 'updated_at',
            width: 70,
        },
        {
            Header: 'Actions',
            width: 60,
            Cell: (cell) => {
                const row = cell.row.original;
            
                return (
                    <a
                        href={`/business_development/edit_opportunity/${row.id}`}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <FaPencilAlt
                            className="actions"
                            title="Edit opportunity"
                        />
                    </a>
                )
                
            }
        }
    ];
}

export default OpportunityReportsTable;

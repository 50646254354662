const ChartContainer = ({children}) => {
    return (
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-1" style={{height: '350px'}}>
            <div className='card border h-100'>
                {children}
            </div>
        </div>
    )
}

export default ChartContainer;